import GATSBY_COMPILED_MDX from "C:/Users/Guillaume/Desktop/dev/Web/Gatsby/webSite/webSite-in-Gatsby/src/pages/blog/pourquoi-j-ai-choisi-react/index.mdx";
import * as React from 'react';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Helmet} from 'react-helmet';
import favicon from '../../images/favicon.ico';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import MoreArticle from '../../components/MoreArticle';
import '../styles/mdx.scss';
var BlogPost = function BlogPost(_ref) {
  var data = _ref.data, children = _ref.children;
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("div", {
    className: "mdx"
  }, React.createElement(Helmet, null, React.createElement("html", {
    lang: "fr"
  }), React.createElement("link", {
    rel: "icon",
    href: favicon
  }), React.createElement("title", null, "Guillaume David | ", data.mdx.frontmatter.title)), React.createElement("p", null, "Guillaume David - ", data.mdx.frontmatter.date), React.createElement(GatsbyImage, {
    image: data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData,
    alt: data.mdx.frontmatter.hero_image_alt,
    className: "mdx_img"
  }), React.createElement("div", {
    className: "mdx_content"
  }, children), React.createElement("div", {
    className: "mdx_more"
  }, "Voir plus d'articles :")), React.createElement(MoreArticle, null), React.createElement(Footer, null));
};
var query = "1635600832";
export var Head = function Head() {
  return React.createElement("title", null, "Guillaume David - ");
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
